"use client";
import { FlatNamespace, TOptions } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation, UseTranslationOptions } from "react-i18next";
import { I18N_SETTINGS } from "../consts/I18N_SETTINGS";
import { initI18n } from "../helpers/initI18n";
import { TranslationKey } from "../types/TranslationKey";

void initI18n();

/**
 * @see https://locize.com/blog/next-13-app-dir-i18n
 */
export function useT(
  lng?: string,
  ns?: FlatNamespace,
  options?: UseTranslationOptions<undefined>,
) {
  const [cookies, setCookie] = useCookies([I18N_SETTINGS.cookieName]);
  const { i18n, t } = useTranslation(ns, options);

  const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);

  useEffect(() => {
    if (activeLng === i18n.resolvedLanguage) {
      return;
    }
    setActiveLng(i18n.resolvedLanguage);
  }, [activeLng, i18n.resolvedLanguage]);

  useEffect(() => {
    if (!lng || i18n.resolvedLanguage === lng) {
      return;
    }
    i18n.changeLanguage(lng).catch(console.error);
  }, [lng, i18n]);

  useEffect(() => {
    if (!activeLng || cookies.i18next_locale === activeLng) {
      return;
    }
    setCookie(I18N_SETTINGS.cookieName, activeLng, { path: "/" });
  }, [setCookie, activeLng, cookies.i18next_locale]);

  return useCallback(
    (key: TranslationKey, options?: TOptions) => {
      if (options) {
        return t(key, options);
      } else {
        return t(key);
      }
    },
    [t],
  );
}
