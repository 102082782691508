import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { I18N_SETTINGS } from "../consts/I18N_SETTINGS";
import { getI18nOptions } from "../helpers/getI18nOptions";

export async function initI18n() {
  try {
    await i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(
        resourcesToBackend(
          (language: string, namespace: string) =>
            import(`../locales/${language}/${namespace}.json`),
        ),
      )
      .init({
        ...getI18nOptions(),
        lng: undefined, // detect the language on client side
        detection: {
          order: ["path", "htmlTag", "cookie", "navigator"],
        },
        // when enable, slash on dates are interpolated - https://www.i18next.com/translation-function/interpolation
        interpolation: { escapeValue: false },
        preload: I18N_SETTINGS.languages,
      });
  } catch (error) {
    console.error(error);
  }
}
