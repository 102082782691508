import { InitOptions } from "i18next";
import { I18N_SETTINGS } from "../consts/I18N_SETTINGS";

export function getI18nOptions(
  lng: string = I18N_SETTINGS.fallbackLng,
  ns: string | string[] = I18N_SETTINGS.defaultNS,
) {
  return {
    supportedLngs: I18N_SETTINGS.languages,
    fallbackLng: I18N_SETTINGS.defaultNS,
    lng,
    fallbackNS: I18N_SETTINGS.defaultNS,
    defaultNS: I18N_SETTINGS.defaultNS,
    ns,
  } satisfies InitOptions;
}
