"use client";
import { Button, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { handleError } from "../Common/helpers/handleError";
import { useT } from "../Translation/hooks/useT";

interface Props {
  error: Error & { digest?: string };
  reset: () => void;
}

/**
 * @see https://nextjs.org/docs/app/api-reference/file-conventions/error
 */
export default function ErrorPage(props: Props) {
  const { error } = props;

  const t = useT();

  useEffect(() => {
    handleError(error).log();
  }, [error]);

  return (
    <Container maxWidth="sm">
      <Stack
        height="100%"
        spacing={2}
        pt={4}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h1">Error</Typography>
        <Typography color="error">{error.message}</Typography>
        <Button href="/">{t("App404Page_Title_GoHome")}</Button>
      </Stack>
    </Container>
  );
}
